import React, {
  useCallback, useContext, useEffect, useRef, useState,
} from "react";
import { observer } from "mobx-react";
import { Button, IFieldPayload } from "@wingmate/toolkit";
import { useTranslation } from "react-i18next";
import { SearchIcon } from "@wingmate/graphics";
import { useStore } from "../../../hooks/useStore";
import { AppContext } from "../../../context/AppProvider";
import { SearchField } from "../../../controls/SearchField/SearchField";
import { GlobalSearchDropdown } from "./GlobalSearchDropdown/GlobalSearchDropdown";

import "./GlobalSearch.scss";

export const GlobalSearch: React.FC = observer(() => {
  const { t } = useTranslation(["default", "common"]);

  const { campaign } = useContext(AppContext);
  const {
    bannerStore, contractStore, globalSearchStore, navigationStore,
  } = useStore();
  const { contracts, isInitialized } = contractStore;
  const { isLoading, isSearchBarOpen, results } = globalSearchStore;
  const { isExpanded } = navigationStore;

  const [searchText, setSearchText] = useState("");
  const [searchCampaignId, setSearchCampaignId] = useState<string>("");
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  useEffect(() => {
    if (isInitialized && contracts.length) {
      const activeContract = contracts.find((contract) => contract.attributes.active);
      setSearchCampaignId(activeContract.attributes.campaign.id);
    } else {
      const getUserContracts = async () => {
        await contractStore.getUserContractsAsync();
      };

      getUserContracts();
    }
  }, [isInitialized, contracts, contractStore]);

  const searchNewLeads = async (value: string, campaignId: string = searchCampaignId) => {
    setDropdownOpen(true);

    try {
      await globalSearchStore.searchNewLeads(
        campaignId,
        value,
      );
    } catch (error) {
      bannerStore.addBanner("red", t("common:navigation.globalSearch.error"), t("common:reload"));
    }
  };

  const loadMoreLeads = async () => {
    try {
      await globalSearchStore.loadMoreLeads(
        searchCampaignId,
        searchText,
      );
    } catch (error) {
      bannerStore.addBanner("red", t("common:navigation.globalSearch.error"), t("common:reload"));
    }
  };

  const handleChange = (payload: IFieldPayload<string | number>) => {
    const value = payload.value as string;
    setSearchText(value);

    const trimmedValue = value.trim();

    if (trimmedValue) {
      searchNewLeads(trimmedValue);
    } else {
      setDropdownOpen(false);
      globalSearchStore.clearResults();
    }
  };

  const handleSearchButtonClick = () => {
    globalSearchStore.setIsSearchBarOpen(true);
  };

  const handleCampaignToggle = (campaignId: string) => {
    setSearchCampaignId(campaignId);
    searchNewLeads(searchText, campaignId);
  };

  const closeAndClearDropdown = useCallback(() => {
    setDropdownOpen(false);
    setSearchText("");
    globalSearchStore.setIsSearchBarOpen(false);
    globalSearchStore.clearResults();
  }, [globalSearchStore]);

  const searchBarRef = useRef<HTMLDivElement>(null);
  const searchDropdownRef = useRef<HTMLDivElement>(null);

  const handleClick = useCallback((event: MouseEvent) => {
    if (
      searchDropdownRef.current?.contains(event.target as HTMLElement)
      || searchBarRef.current?.contains(event.target as HTMLElement)
    ) {
      setDropdownOpen(true);
    } else {
      closeAndClearDropdown();
    }
  }, [closeAndClearDropdown]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);
    return () => document.removeEventListener("mousedown", handleClick);
  }, [handleClick]);

  const renderSearchBar = () => {
    if (searchCampaignId && isSearchBarOpen) {
      return (
        <div ref={searchBarRef} className="GlobalSearch" data-testid="GlobalSearch">
          <SearchField
            className="GlobalSearch__TextField"
            autoFocus
            id="global-search-textfield"
            placeholder={t("common:navigation.globalSearch.placeholder", {
              leadLabel: campaign.leadLabel.toLowerCase(),
            }) as string}
            value={searchText}
            onChange={handleChange}
          />
        </div>
      );
    }

    return null;
  };

  return (
    <>
      {!isSearchBarOpen && (
        <Button className="GlobalSearch__Button" data-testid="GlobalSearch__Button" onClick={handleSearchButtonClick}>
          <SearchIcon height={20} width={20} />
        </Button>
      )}
      {renderSearchBar()}
      {isDropdownOpen && (
        <GlobalSearchDropdown
          campaignLeadCount={results?.[searchCampaignId]?.totalHits}
          className={isExpanded ? "sideNavExpanded" : ""}
          closeDropdown={closeAndClearDropdown}
          contracts={contracts}
          forwardRef={searchDropdownRef}
          isLoading={isLoading}
          leads={results?.[searchCampaignId]?.leads || []}
          loadMoreLeads={loadMoreLeads}
          onCampaignChange={handleCampaignToggle}
          searchText={searchText}
        />
      )}
    </>
  );
});

export default GlobalSearch;
