import {
  action, computed, makeAutoObservable, observable,
} from "mobx";
import * as userPerformanceService from "../requests/users/performances";
import { IPerformance } from "../types/IPerformance";
import { RootStore } from "./RootStore";

export class PerformancesStore {
  rootStore;

  @observable performances: IPerformance[] = [];

  @observable isLoading = true;

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  @computed get currentUserPerformances() {
    const { currentUser } = this.rootStore.context;

    return this.performances.filter(
      (performance) => performance.attributes.userId === currentUser.id
        && performance.id === null,
    );
  }

  @computed get currentUserCampaignPerformances() {
    const { campaign } = this.rootStore.context;

    return this.currentUserPerformances.filter(
      (performance) => performance.attributes.campaignId === campaign.id,
    );
  }

  @action
  setPerformances(performances: IPerformance[]) {
    this.performances = performances;
  }

  @action
  setIsLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  performancesForUser(userId: string) {
    if (userId !== undefined && userId !== null) {
      return this.performances.filter(
        (performance) => performance.attributes.userId.toString() === userId.toString(),
      );
    }
    return [];
  }

  @action
  async getPerformancesAsync(userId: string) {
    this.setIsLoading(true);
    const performances = await userPerformanceService.getPerformances(userId);

    this.setPerformances(performances);
    this.setIsLoading(false);

    return performances;
  }
}

export default PerformancesStore;
